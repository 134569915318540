<template>
  <div class="row">
    <div class="card col-md-12">
      <div class="card-header">
        <h5>
          <i class="nc-icon nc-chart-bar-32"></i>
          {{ $t('strategiesforbroker') }}
        </h5>
        <hr>
      </div>
      <div class="card-body">

        <div class="row">

          <div class="col-sm-2">
            <label>{{ $t('searchtxt') }}</label>
            <fg-input
              class="input-sm"
              :placeholder="searchtxt"
              v-model="search"
              v-on:change="loadList"
              addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>

          <div class="col-md-2">
            <label>{{ $t('orderbytxt') }}</label>
            <select class="form-control"
                    v-model="sortby.column"
                    v-on:change="this.loadList">
              <option
                v-for="item in sortby.columns"
                :key="item.prop"
                :value="item.prop">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <label>{{ $t('ordertxt') }}</label>
            <select class="form-control"
                    v-model="sortby.order"
                    v-on:change="this.loadList">
              <option
                v-for="item in sortby.orders"
                :key="item.prop" :label="item.label" :value="item.prop">
                {{ item.label }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <label>{{ $t('rowstxt') }}</label>
            <select class="form-control"
                    v-model="pagination.perPage"
                    v-on:change="this.loadList">
              <option
                v-for="item in pagination.perPageOptions"
                :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>

          <div class="col-sm-4">
            <vue-excel-xlsx
              v-if="!loading"
              style="top: +15px;position: relative;"
              class="btn btn-info pull-right"
              :data="exportdata"
              :columns="exportcols"
              :filename="exportfilename"
              :sheetname="exportsheetname">
              <i class="fa fa-download"></i>
              {{ $t('exportexcel') }}
            </vue-excel-xlsx>
          </div>

        </div>

        <el-table class="table-striped"
                  :data="strategies"
                  :emptyText="emptyrow"
                  border
                  style="width: 100%">

          <el-table-column :label="txt.url" width="100px" align="center">
            <template slot-scope="props">
              <a :href="`/accounts/details/${props.row.accid}`" targer="_blank">
                <p-button type="info"
                          size="sm"
                          link >
                  <i class="nc-icon nc-alert-circle-i"></i>
                </p-button>
              </a>
            </template>
          </el-table-column>

          <el-table-column :label="txt.strat_name">
            <template slot-scope="props">
              {{ props.row.strategy }}
            </template>
          </el-table-column>

          <el-table-column :label="txt.master">
            <template slot-scope="props">
              {{ props.row.account }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.connected"
            width="200px">
            <template slot-scope="props">
              {{ props.row.connected }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.balance"
            width="200px">
            <template slot-scope="props">
              {{ parseFloat(props.row.balance || 0).toFixed(2) }}
            </template>
          </el-table-column>

          <el-table-column
            align="right"
            :label="txt.equity"
            width="200px">
            <template slot-scope="props">
              {{ parseFloat(props.row.equity || 0).toFixed(2) }}
            </template>
          </el-table-column>

        </el-table>

      </div>
      <div class="card-footer">
        <hr>
        <p-pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>

</template>
<script>
import Vue from 'vue'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {Table,Tooltip} from 'element-ui'
import {Card} from "@/components/UIComponents";
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx)

const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  components: {
    Card,
    PPagination,
    Table,
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      broker: user_data.app_metadata.broker,
      loading: true,
      search: '',
      searchtxt: this.$t('searchtxt'),
      exportdata: [],
      exportfilename: '',
      exportsheetname: this.$t('statementtxt'),
      emptyrow: this.$t('nostrategiesfound'),
      helper_performancetrades: this.$t('performancetrades_tooltip'),
      strategies: [],
      exportcols: [
        { label: this.$t('strategytxt'), field: "strategy" },
        { label: this.$t('master'), field: "account" },
        { label: this.$t('connected'), field: "connected" },
        { label: this.$t('balance'), field: "balance" },
        { label: this.$t('equity'), field: "equity" },
      ],
      txt: {
        url: 'URL',
        strat_name: this.$t('strategytxt'),
        balance: this.$t('balance'),
        equity: this.$t('equity'),
        connected: this.$t('connected'),
        master: this.$t('master')
      },
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      sortbyOrder: this.$t('sortbyorder'),
      filterby: {
        element: '',
        elements: [
          {
            prop: '',
            label: this.$t('alltxt')
          },
          {
            prop: 'fol',
            label: this.$t('connected')
          },
          {
            prop: 'nonfol',
            label: this.$t('unassigned')
          }
        ]
      },
      sortby: {
        column: 'equity',
        order: 'desc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        columns: [
          {
            prop: 'strategy',
            label: this.$t('strategytxt')
          },
          {
            prop: 'balance',
            label: this.$t('balance')
          },
          {
            prop: 'equity',
            label: this.$t('equity')
          },
          {
            prop: 'connected',
            label: this.$t('connected')
          }
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 0
      },
    }
  },
  methods: {
    goInfo(data) {
      let user = data.userid.toLowerCase()
      window.open("/accounts/details/" + data.accid, '_blank')
    },
    showFollowerAlert(){
      this.$toast.info(this.helper_performancetrades);
    },
    getPaginate() {
      return this.pagination
    },
    getFilter() {
      return {
        strat: this.search,
        connect: this.filterby.element,
      }
    },
    getOrder() {
      return {
        orderBy: this.sortby.column,
        order: this.sortby.order,
      }
    },
    loadList() {
      this.loading = true;
      this.$listBrokerStrategies({
        broker: this.broker,
        paginate: this.getPaginate(),
        filter: this.getFilter(),
        order: this.getOrder()
      }).then(this.successHandler, this.errorHandler)

      this.$listBrokerStrategies({
        broker: this.broker,
        filter: this.getFilter(),
        order: this.getOrder()
      }).then(this.successExportHandler, this.errorExportHandler)
    },
    successHandler(resp) {
      if (resp.success) {
        this.strategies = resp.data.strategies;
        this.pagination.total = resp.data.count;
        const now = this.$moment('timezone', 'Europe/London', 'LLLL ss').format("YYYY-MM-DD");
        this.exportfilename = this.$t('strategies') +"-"+ now;
      }
    },
    errorHandler() {
    },
    successExportHandler(resp) {
      if (resp.success) {
        this.exportdata = resp.data.strategies;
        this.loading = false;
      }
    },
    errorExportHandler() {
      this.exportdata = [];
      this.loading = false;
    }
  },
  mounted() {
    this.loadList()
  },
  watch: {
    "pagination.currentPage": function () {
      this.loadList()
    }
  },
  created() {
    this.$setTranslatingInProgress();
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress");
  }
}
</script>

<style>
</style>
